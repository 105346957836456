// theme
export const LOAD_THEME = '[theme] load theme'

// questions
export const LOAD_QUESTIONS = '[assessmentplayer questions] load questions'
export const GOTO_QUESTION = '[questions] goto question'
export const AUTHOR_QUESTION = 'author question'
export const ADD_ANSWER = '[questions] add answer'
export const LOAD_PREVIOUS_ANSWERS = '[questions] load previous answers'
export const REMOVE_PREVIOUS_ANSWERS = '[questions] remove previous answer'
export const ADD_EVALUATION = '[questions] add evulation'
export const TOGGLE_ADVANCED_SECTIONS = '[questions] toggle advanced sections'
// assessment
export const LOAD_ASSESSMENT = '[assessment] load assessment'
export const START_ASSESSMENT = '[assessment] start assessment'

// test
export const SET_TEST_ID = '[test] set test id'
export const LOAD_TEST = '[test] load test'
export const LOAD_TEST_ITEMS = '[test] load test items'
export const RESET_TEST_ITEMS = '[test] reset test items'
export const LOAD_PREVIOUS_RESPONSES = '[test] load previous responses'
export const LOAD_PREVIOUS_RESPONSES_REQUEST =
  '[test] load previous responses request'
export const SET_RESUME_STATUS = '[test] set resume status'
export const SET_TEST_LOADING_STATUS = '[test] set loading status'
export const SET_TEST_LOADING_ERROR = '[test] set loading status error'
export const SET_CHECK_ANSWER_PROGRESS_STATUS =
  '[test] set check answer progress status'
export const SET_PREVIEW_LANGUAGE = '[test] language switch preview'
export const SET_IS_TEST_PREVIEW_VISIBLE = '[test] set is preview'

export const COUNT_CHECK_ANSWER = '[test] increase check answer count'
export const SET_PASSWORD_VALIDATE_STATUS =
  '[test] set password validated status'
export const SET_PASSWORD_VALIDATE_MODAL_VISIBLE =
  '[test] set password modal visibility'
export const GET_ASSIGNMENT_PASSWORD = '[test] get assignment password'
export const GET_ASSIGNMENT_PASSWORD_ON_START =
  '[test] get assignment password on start'
export const SET_START_ASSIGNMENT_PAYLOAD =
  '[test] set assignment start payload'
export const TEST_ACTIVITY_LOADING = '[test] loading test activity'
export const SET_PASSWORD_STATUS_MESSAGE = '[test] set password status message'
export const UPDATE_CURRENT_AUDIO_DEATILS = '[test] audio player details'

// items
export const RECEIVE_ITEMS_REQUEST = '[items] receive items request'
export const RECEIVE_ITEMS_SUCCESS = '[items] receive items success'
export const RECEIVE_ITEMS_ERROR = '[items] receive items error'
export const RECEIVE_ITEM_REQUEST = '[items] receive item request'
export const RECEIVE_ITEM_SUCCESS = '[items] receive item success'
export const RECEIVE_ITEM_ERROR = '[items] receive item error'
export const CREATE_ITEM_REQUEST = '[items] create item request'
export const UPDATE_ITEM_REQUEST = '[items] update item request'
export const SAVE_USER_RESPONSE = '[items] save user response'
export const SAVE_BLUR_TIME = '[anticheating] set blur time'
export const SET_SAVED_BLUR_TIME = '[anticheating] set saved blur time'
export const SAVE_USER_RESPONSE_SUCCESS = '[items] save user response success'
export const SAVE_USER_RESPONSE_ERROR = '[items] save user response error'
export const LOAD_USER_RESPONSE = '[items] load user response'
export const GOTO_ITEM = '[items] goto a particular item'
export const SAVE_TESTLET_USER_RESPONSE = '[items] save testlet user response'

// QuestionCommon
export const QUESTIONCOMMON_UPDATE_QUESTIONS_LIST =
  '[QuestionCommon] QUESTIONCOMMON_UPDATE_QUESTIONS_LIST'
export const QUESTIONCOMMON_UPDATE_VALIDATION =
  '[QuestionCommon] QUESTIONCOMMON_UPDATE_VALIDATION'
export const QUESTIONCOMMON_UPDATE_STIMULUS =
  '[QuestionCommon] QUESTIONCOMMON_UPDATE_STIMULUS'
export const QUESTIONCOMMON_SET_STATE =
  '[QuestionCommon] QUESTIONCOMMON_SET_STATE'
export const QUESTIONCOMMON_CLEAR = '[QuestionCommon] QUESTIONCOMMON_CLEAR'
export const QUESTIONCOMMON_ADD_ALT_RESPONSES =
  '[QuestionCommon] QUESTIONCOMMON_ADD_ALT_RESPONSES'
export const QUESTIONCOMMON_UPDATE_ALT_VALIDATION_SCORE =
  '[QuestionCommon] QUESTIONCOMMON_UPDATE_ALT_VALIDATION_SCORE'
export const QUESTIONCOMMON_UPDATE_CORRECT_VALIDATION_SCORE =
  '[QuestionCommon] QUESTIONCOMMON_UPDATE_CORRECT_VALIDATION_SCORE'

// Answers
export const SET_ANSWER = '[answers] set answer'
export const LOAD_ANSWERS = '[answers] load answers'
export const CHECK_ANSWER = '[answers] check answers'
export const CHECK_ANSWER_EVALUATION = '[answers] check answers evaluation'
export const CLEAR_ANSWERS = '[answers] clear answers'
export const REMOVE_ANSWERS = '[answers] remove answers'

// Evalution
export const ADD_ITEM_EVALUATION = '[evaluation] add evaluation'
export const CLEAR_ITEM_EVALUATION = '[evaluation] clear evaluation'

// testItem view
export const CHANGE_PREVIEW = '[view] change view'

// test acitivty
export const FINISH_TEST = '[test] finish test'
export const SUBMIT_SECTION = '[test] submit section by id'
export const START_SECTION = '[test] start section by id'
export const SET_TEST_ACTIVITY_ID = '[test] add test activity id'
export const RESET_CURRENT_TEST_ITEM = '[test] reset current item'
export const SET_SAVE_USER_RESPONSE = '[test] set save user respose'
export const SET_SECTION_SUBMIT = '[test] set section submit'
export const SET_SUBMIT_TEST_COMPLETE = '[test] set sumbit test complete'
export const CLOSE_TEST_TIMED_OUT_ALERT_MODAL =
  '[test] close test timed out alert modal'

// file
export const FILE_UPLOAD_REQUEST = '[test] upload file request'
export const FILE_UPLOAD_SUCCESS = '[test] upload file success'
export const FILE_UPLOAD_ERROR = '[test] upload file error'

// scratchPad
export const SAVE_USER_WORK = '[userWork] save userWork'
export const RESET_UPDATE_KEY = '[userWork] reset update key'
export const LOAD_SCRATCH_PAD = '[userWork] load scratchPad data'
export const LOAD_SCRATCH_PAD_SAVED =
  '[userWork] load scratchPad data maintaining state'
export const CLEAR_USER_WORK = '[userWork] clear userWork'
export const SAVE_HINT_USAGE = '[hintUsage] save hintUsage'
export const CLEAR_HINT_USAGE = '[hintUsage] clear hintUsage'

// Test Level User Work
export const SAVE_TEST_LEVEL_USER_WORK = '[testUserWork] save userWork'
export const SET_TEST_LEVEL_USER_WORK = '[testUserWork] set userWork'
export const LOAD_TEST_LEVEL_USER_WORK =
  '[testUserWork] load testlevel userWork'

// shuffled options
export const SET_SHUFFLED_OPTIONS = '[shuffledOptions] set shuffled options'

// graphTools
export const SET_ELEMENTS_STASH = '[graphTools] set elements stash'
export const SET_STASH_INDEX = '[graphTools] set stash index'

// testlet
export const SAVE_TESTLET_LOG = '[testlet] save testlet log'
export const SAVE_TESTLET_LOG_SUCCESS = '[testlet] save testlet success'
export const SAVE_TESTLET_LOG_FAILURE = '[testlet] save testlet failure'

// hints
export const SHOW_HINTS = '[hints] show hints'
export const Hide_HINTS = '[hints] hide hints'

// assessmentPlayer
export const STORE_OPTIONS = '[assessmentPlayer] store order of options'
export const CLEAR_OPTIONS = '[assessmentPlayer] clear order of options stored'
export const SWITCH_LANGUAGE =
  '[assessmentPlayer] switch language during attempt'
export const LANG_CHANGE_SUCCESS = '[assessmentPlayer] language switch success'
export const UPDATE_PLAYER_PREVIEW_STATE =
  '[assessmentPlayer] update player settings'
export const SET_VIEW_TEST_INFO_SUCCESS =
  '[assessmentPlayer] view test info success'

// userInteraction passage
export const SET_PASSAGE_CURRENT_PAGE = '[passage] set passage current page'

// Media recording
export const SET_MEDIA_RECORDING_STORE_DATA =
  '[media] set media recording store data'
export const SET_MEDIA_RECORDING_STATE = '[media] set media recording state'
export const CLEAR_MEDIA_RECORDING_STATE = '[media] clear media recording state'
export const SET_STOP_AUDIO_RECORDING_AND_UPLOAD_FOR_QID =
  '[audioRecording] set stop audio recording and upload for qid'
export const SET_MEDIA_UPLOAD_STATUS = '[media] set media upload status'
export const AUDIO_UPLOAD_COMPLETE_FOR_QID =
  '[audioRecording] audio upload complete for qid'
export const SET_ANTI_CHEATING_ENABLED = '[test] set anti cheating enabled'
