const homePlaylistPath = '/home/playlist'
const homeStudentAssignmentsPath = '/home/assignments'
const studentSebQuitConfirmpath = '/student/seb-quit-confirm'
const homeStudentGradesPath = '/home/grades'

const toolMap = {
  CROSS_OUT: 3,
}

const magnifierSizes = {
  SMALL: 'small',
  MEDIUM: 'medium',
}

export {
  homePlaylistPath,
  homeStudentAssignmentsPath,
  studentSebQuitConfirmpath,
  homeStudentGradesPath,
  toolMap,
  magnifierSizes,
}
